// import { faker } from '@faker-js/faker'
// import type { LearningSession } from '@t16t/sdk'

import { ApiClient, CreateLearningSession, CreateLearningSessionFormData, FinishLearningSession, LearningSession, LearningSessionDetails, SubmitLearningSessionAnswers } from "@t16t/sdk"

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class LearningSessionService {

    async fetchLearningSessions(): Promise<ApiResponse<LearningSession[]>> {
        const { data, error } = await ApiClient.getClient().GET('/api/v1/learning-sessions')

        return {
            errorMessage: error? 'Something went wrong' : undefined,
            data: data
        };
    }

    async fetchLearningSession(uuid: string): Promise<ApiResponse<LearningSessionDetails>> {
        const { data, error } = await ApiClient.getClient().GET('/api/v1/learning-sessions/{learning_session_entity_id}', {
            params: {
              header: {
                SAAS_TENANT_ENTITY_ID: 'a38b97de-eee5-4e0c-a29e-83586bac94df',
              },
              path: {
                learning_session_entity_id: uuid,
              },
            },
        })

        return {
            errorMessage: error? 'Something went wrong' : undefined,
            data: data
        }
    }

    async createLearningSession(data: CreateLearningSession): Promise<ApiResponse<void>> {
        const { error } = await ApiClient.getClient().POST('/api/v1/learning-sessions', {
            body: data,
          });

        return {
            errorMessage: error? 'Something went wrong' : '',
            data: undefined
        }
    }

    async fetchCreateLearningSessionFormData(): Promise<ApiResponse<CreateLearningSessionFormData>> {
        const { data, error } = await ApiClient.getClient().GET('/api/v1/learning-sessions/create-form-data')
        return {
            errorMessage: error? 'Something went wrong' : '',
            data: data
        }
    }

    async startLearningSession(uuid: string): Promise<ApiResponse<void>>  {
        const { error } = await ApiClient.getClient().PUT('/api/v1/learning-sessions/{learning_session}/:start', {
            body: { data: {}, properties: { shouldPoll: false, pollingTimeout: 10 } },
            params: {
              header: {
                SAAS_TENANT_ENTITY_ID: 'a38b97de-eee5-4e0c-a29e-83586bac94df',
              },
              path: {
                learning_session: uuid,
              },
            },
          })
        
        return {
            errorMessage: error? 'Something went wrong' : '',
            data: undefined
        }
    }

    async submitLearningSession(uuid: string, data: SubmitLearningSessionAnswers): Promise<ApiResponse<void>> {
        // TODO shouldPoll be true
        const { error } = await ApiClient.getClient().POST('/api/v1/learning-sessions/{learning_session}/:submit-answers', {
            body: data,
            params: {
                header: {
                    SAAS_TENANT_ENTITY_ID: 'a38b97de-eee5-4e0c-a29e-83586bac94df',
                },
                path: {
                    learning_session: uuid,
                },
            },
        });

        return {
            errorMessage: error? 'Something went wrong' : '',
            data: undefined
        }
    }

    async finishLearningSession(uuid: string, data: FinishLearningSession): Promise<ApiResponse<void>> {
        // TODO shouldPoll be true
        const { error } = await ApiClient.getClient().POST('/api/v1/learning-sessions/{learning_session}/:finish', {
            body: data,
            params: {
                header: {
                    SAAS_TENANT_ENTITY_ID: 'a38b97de-eee5-4e0c-a29e-83586bac94df',
                },
                path: {
                    learning_session: uuid,
                },
            },
        });

        return {
            errorMessage: error? 'Something went wrong' : '',
            data: undefined
        }
    }
}